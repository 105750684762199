import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const azureStorageApi = createApi({
  reducerPath: 'azureStorageApi',
  baseQuery: fetchBaseQuery({ 
    baseUrl: process.env.REACT_APP_STORAGE_URL,
  }),
  endpoints: (builder) => ({
    uploadImage: builder.mutation({
      query: (file) => {
        const formData = new FormData();
        formData.append('file', file);
        
        return {
          url: `/campaign-wa/${file.name}?${process.env.REACT_APP_STORAGE_TOKEN}`,
          method: 'PUT',
          body: file,
          headers: {
            'x-ms-blob-type': 'BlockBlob',
            'Content-Type': file.type
          },
        };
      },
    }),
  }),
});

export const { useUploadImageMutation } = azureStorageApi; 