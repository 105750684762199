import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './api';

export const campaignApi = createApi({
  reducerPath: 'campaignApi',
  baseQuery: fetchBaseQuery({ 
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers) => {
      headers.set('Content-Type', 'application/json');
      return headers;
    }
  }),
  endpoints: (builder) => ({
    searchCampaigns: builder.mutation({
      query: (params) => ({
        url: '/api/campaign/search',
        method: 'POST',
        body: params
      })
    }),
    
    searchRecipients: builder.mutation({
      query: (params) => ({
        url: '/api/campaign/recipients/search',
        method: 'POST',
        body: params
      })
    }),

    getCampaignById: builder.query({
      query: (id) => `/api/campaign/${id}`
    }),

    createCampaign: builder.mutation({
      query: (values) => ({
        url: '/api/campaign',
        method: 'POST',
        body: values
      })
    }),

    sendMessage: builder.mutation({
      query: (body) => ({
        url: '/api/whatsapp',
        method: 'POST',
        body: body,
        headers: {
          'Authorization': `ApiKey ${process.env.API_KEY}`
        }
      })
    })
  })
});

export const {
  useSearchCampaignsMutation,
  useSearchRecipientsMutation,
  useGetCampaignByIdQuery,
  useCreateCampaignMutation,
  useSendMessageMutation
} = campaignApi; 