import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const templateApi = createApi({
  reducerPath: 'templateApi',
  baseQuery: fetchBaseQuery({ 
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers) => {
      headers.set('Content-Type', 'application/json');
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getTemplates: builder.query({
      queryFn: () => {
        return {
          data: [
//             {
//               id: 1,
//               templateKey: "test01",
//               body: [{ text: `
//                 *Statement available*
// This is to notify you that your latest statement for your {{1}} account is now available. 

// Please log into your account to view your statement.
//                 ` }],
//             },
            // {
            //   id: 2,
            //   templateKey: "welcome_message",
            //   body: [{ text: "Welcome {{1}}! Thank you for choosing our service." }],
            //   header: { text: "Welcome Message" }
            // },
            // {
            //   id: 3,
            //   templateKey: "promo_image", 
            //   body: [{ text: "Check out our latest promotion! Use code {{1}} for {{2}}% off." }],
            //   header: { image: true }
            // },
            // {
            //   id: 4,
            //   templateKey: "appointment_reminder",
            //   body: [{ text: "Hi {{1}}, this is a reminder for your appointment on {{2}}." }],
            //   header: { text: "Appointment Reminder" }
            // },
            // {
            //   id: 5,
            //   templateKey: "order_confirmation",
            //   body: [{ text: "Your order #{{1}} has been confirmed. Total amount: ${{2}}" }],
            //   header: { text: "Order Confirmation" }
            // },
            // {
            //   id: 6,
            //   templateKey: "feedback_request",
            //   body: [{ text: "How was your experience with us? We'd love to hear your feedback!" }],
            //   header: { text: "We Value Your Feedback" }
            // },
            {
              id: 1,
              templateKey: "vendors_test",
              body: [{ text: "flynas, the Saudi air carrier and the leading low-cost airline in the Middle East,{{1}}" }],
              header: { document: true }
            }
          ]
        };
      }
    })
  })
});

export const {
  useGetTemplatesQuery
} = templateApi; 