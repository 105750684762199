import { configureStore } from '@reduxjs/toolkit';
import { campaignApi } from '../services/campaignApi';
import { templateApi } from '../services/templateApi';
import { azureStorageApi } from '../services/azureStorageApi';
export const store = configureStore({
  reducer: {
    [campaignApi.reducerPath]: campaignApi.reducer,
    [templateApi.reducerPath]: templateApi.reducer,
    [azureStorageApi.reducerPath]: azureStorageApi.reducer,
    // ... other reducers
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      campaignApi.middleware,
      templateApi.middleware,
      azureStorageApi.middleware
    )
}); 